<template>
  <div class="content">
    <div class="main-content" ref="containerRef">
      <a-spin tip="Loading..." :spinning="loading">
        <a-form v-if="order" style="padding-bottom: 100px">
          <div style="font-weight: 800">
            <e-info title="台位名称" :value="order.tableName" />
            <e-info
              title="就餐时间"
              :value="$filters.formatDate(order.bookTime, 'YYYY-MM-DD HH:mm')"
            />
            <e-info title="套餐名称" :value="order.comboName" />

            <a-row style="padding-left: 2px">
              <a-col :span="6" :offset="1">
                <e-info
                  :labelCol="12"
                  :valueCol="12"
                  title="餐标"
                  :value="order.comboPrice"
                />
              </a-col>
              <a-col :span="4">
                <e-info
                  :labelCol="12"
                  :valueCol="12"
                  title="人数"
                  :value="order.peopleCount"
                />
              </a-col>
              <a-col :span="4">
                <e-info
                  title="套餐价格"
                  :labelCol="12"
                  :valueCol="12"
                  :value="order.peopleCount * order.comboPrice"
                />
              </a-col>
            </a-row>

            <a-row style="padding-left: 2px">
              <a-col :span="6" :offset="1">
                <e-info
                  title="成本价"
                  :labelCol="12"
                  :valueCol="12"
                  :value="'¥' + price"
                />
              </a-col>
              <a-col :span="4">
                <e-info
                  title="菜品售价"
                  :labelCol="12"
                  :valueCol="12"
                  :value="'¥' + sellingPrice"
                  :valueStyle="
                    sellingPrice > order.peopleCount * order.comboPrice
                      ? 'color: #f50'
                      : ''
                  "
                />
              </a-col>
            </a-row>
          </div>

          <a-row>
            <a-col :span="4">
              <div class="label-title" style="font-weight: 800">菜品</div>
            </a-col>
            <a-col :span="14">
              <div class="fields">
                <a-table
                  :columns="columns"
                  :data-source="orderItems"
                  :pagination="false"
                  :loading="loading"
                  :bordered="true"
                  :row-key="(record) => record.id"
                >
                  <template #bodyCell="{ column, record, index }">
                    <template v-if="column.key === 'index'">
                      {{ index + 1 }}
                    </template>

                    <template v-if="column.key === 'dishname'">
                      {{ record.dish.name }}
                    </template>

                    <template v-if="column.key === 'type'">
                      {{ record.dish.isPersonal ? "例" : "份" }}
                    </template>

                    <template v-if="column.key === 'price'">
                      ¥{{ record.dish.price }}
                    </template>

                    <template v-if="column.key === 'sellingPrice'">
                      ¥{{ record.dish.sellingPrice }}
                    </template>

                    <template v-if="column.key === 'quantity'">
                      <a-button
                        type="link"
                        :disabled="record.quantity <= 1"
                        @click.stop="minus(index)"
                      >
                        <minus-outlined />
                      </a-button>
                      <a-input-number
                        v-model:value="record.quantity"
                        :min="1"
                      />
                      <a-button type="link" @click.stop="add(index)">
                        <plus-outlined />
                      </a-button>
                    </template>

                    <template v-if="column.key === 'action'">
                      <a-tooltip>
                        <template #title>删除</template>
                        <a @click.stop="delFields(index)">
                          <DeleteOutlined />
                        </a>
                      </a-tooltip>
                    </template>
                  </template>
                </a-table>

                <!-- <a-button type="primary" @click="addFields">添加菜品</a-button> -->
                <a-button type="primary" @click="$refs.dishes.open()">添加菜品</a-button>
              </div>
            </a-col>
          </a-row>
        </a-form>
      </a-spin>

      <div class="form-footer">
        <a-button
          @click="onSubmitFun"
          :loading="loading"
          size="large"
          type="primary"
        >
          提交
        </a-button>

        <a-button
          @click="$refs.printDish.open(order)"
          size="large"
          type="primary"
          style="margin-left: 20px"
        >
          打印菜单
        </a-button>

        <a-button
          @click="onSettlementPrint(order.id)"
          size="large"
          type="primary"
          style="margin-left: 20px"
        >
          打印菜单小票
        </a-button>

        <a-button
          @click="fetchData"
          size="large"
          danger
          type="primary"
          style="margin-left: 20px"
        >
          重置
        </a-button>
      </div>

      <e-modal
        :visible="visible"
        :title="title"
        :width="600"
        @close="onClose"
        @ok="onAddFieldFun"
      >
        <a-form>
          <SearchDish
            :validate="validateInfos.dishId"
            v-model:value="modelRef.dishId"
            @ok="chooseDish"
          />

          <a-row style="padding-left: 2px">
            <a-col :span="7" :offset="1">
              <e-input-number
                title="数量"
                v-model:value="modelRef.quantity"
                :min="1"
                :step="1"
              />
            </a-col>
          </a-row>
        </a-form>
      </e-modal>

      <Dishes ref="dishes" @ok="onPushDish" />
      <PrintDish ref="printDish" />
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, toRaw, reactive, createVNode } from 'vue'
import {
  DeleteOutlined,
  MinusOutlined,
  PlusOutlined,
  QuestionCircleOutlined
} from '@ant-design/icons-vue'

import { OrderClass } from '@/apis/order'

import form from '@/mixins/form'
import { Form, Modal, message } from 'ant-design-vue'
import SearchDish from '@/components/form/SearchDish'
import { DishClass } from '@/apis/dish'
import Dishes from '@/components/forms/combo/Dishes'
import PrintDish from '@/components/forms/order/PrintDish'

const useForm = Form.useForm
const api = new OrderClass()
const dish = new DishClass()
export default defineComponent({
  mixins: [form],
  emits: ['ok'],
  components: {
    DeleteOutlined,
    SearchDish,
    MinusOutlined,
    PlusOutlined,
    Dishes,
    PrintDish
  },
  setup () {
    const loading = ref(false)
    const loading2 = ref(false)
    const id = ref('')
    const index = ref(-1)
    const fields = ref([])

    const order = ref()
    const orderItems = ref([])
    const price = ref(0)
    const sellingPrice = ref(0)

    const visible = ref(false)
    const title = ref('')
    const onClose = () => {
      resetFields()
      index.value = -1
      visible.value = false
    }

    const modelRef = reactive({
      dishId: '',
      dish: {},
      price: 0,
      sellingPrice: 0,
      quantity: 1
    })
    const rulesRef = reactive({
      dishId: [
        {
          required: true,
          message: '请选择菜品'
        }
      ]
    })
    const { resetFields, validate, validateInfos } = useForm(
      modelRef,
      rulesRef
    )

    const chooseDish = (e) => {
      modelRef.dish = e
    }

    const onPushDish = (e) => {
      loading2.value = true
      dish.getList(e).then((resp) => {
        resp.map((e) => {
          orderItems.value.push({
            dishId: e.id,
            dish: e,
            orderId: id.value,
            price: e.price,
            quantity: 1,
            sellingPrice: e.sellingPrice
          })
        })
        loading2.value = false
      })
    }

    const onSettlementPrint = (id) => {
      api.comboPrint(id).then(() => {
        message.success('菜单打印成功')
      })
    }

    return {
      loading,
      id,
      price,
      sellingPrice,
      fields,
      visible,
      title,
      order,
      onClose,
      chooseDish,
      index,
      modelRef,
      validate,
      onPushDish,
      onSettlementPrint,
      loading2,
      validateInfos,
      orderItems,
      columns: [
        {
          title: '序号',
          dataIndex: 'index',
          key: 'index',
          width: 70
        },
        {
          title: '菜品',
          key: 'dishname',
          dataIndex: 'dishname'
        },
        {
          title: '菜品类型',
          key: 'type',
          dataIndex: 'type',
          width: 100
        },
        {
          title: '成本价',
          key: 'price',
          dataIndex: 'price',
          width: 100
        },
        {
          title: '售价',
          key: 'sellingPrice',
          dataIndex: 'sellingPrice',
          width: 100
        },
        {
          title: '数量',
          key: 'quantity',
          dataIndex: 'quantity',
          width: 250
        },
        {
          title: '删除',
          key: 'action',
          dataIndex: 'action',
          width: 80
        }
      ]
    }
  },

  created () {
    this.id = this.$route.params.id
    this.fetchData()

    this.$watch('stockPrice', (e) => {
      this.price = e.price
      this.sellingPrice = e.sellingPrice
    })
  },

  unmounted () {},

  computed: {
    stockPrice () {
      const items = this.orderItems

      if (items.length === 0) return 0
      let res = 0
      let res2 = 0
      items.map((e) => {
        res += e.dish.price * e.quantity
        res2 += e.dish.sellingPrice * e.quantity
      })

      res = res.toFixed(2)
      return {
        price: Number(res),
        sellingPrice: Number(res2)
      }
    }
  },

  methods: {
    fetchData () {
      if (this.id) {
        this.loading = true
        api.detail(this.id).then((resp) => {
          this.order = resp

          this.orderItems = resp.orderItems.filter((x) => x.type === 0)

          this.loading = false
        })
      }
    },

    onSubmitFun () {
      const _this = this
      const items = []
      this.orderItems.map((e) => {
        items.push({
          dishId: e.dishId,
          orderId: e.orderId,
          quantity: e.quantity
        })
      })

      Modal.confirm({
        title: '注意！',
        icon: createVNode(QuestionCircleOutlined),
        content: createVNode(
          'div',
          {
            style: 'color:red;'
          },
          '确定修改套餐菜单吗？'
        ),
        okText: '确认修改',
        onOk () {
          console.log(items)
          _this.loading = true
          api.orderItems(items).then(() => {
            message.success('修改成功')
            _this.fetchData()
          })
        },

        onCancel () {
          console.log('Cancel')
        }
      })
    },

    addFields () {
      this.title = '添加菜品'
      this.visible = true
    },
    onAddFieldFun () {
      this.validate()
        .then(() => {
          const data = toRaw(this.modelRef)
          this.orderItems.push({
            dishId: data.dishId,
            dish: data.dish,
            orderId: this.id,
            price: data.dish.price,
            quantity: data.quantity,
            sellingPrice: data.dish.sellingPrice
          })
          this.onClose()
        })
        .catch((e) => {
          console.log(e)
        })
    },
    editFields (index) {
      this.title = '编辑菜品'
      this.index = index
      this.modelRef = this.orderItems[index]
      this.visible = true
    },
    delFields (index) {
      this.orderItems.splice(index, 1)
    },
    minus (index) {
      this.orderItems[index].quantity--
    },
    add (index) {
      this.orderItems[index].quantity++
    }
  }
})
</script>

<style lang="less" scoped>
.form-footer {
  position: absolute;
  bottom: 9px;
  left: 0;
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  background: #fff;
  box-shadow: 0 -5px 5px rgba(0, 0, 0, 0.1);
  z-index: 10003;
  border-radius: 0 0 15px 15px;
  padding: 0 20px;
}
</style>
